.routine[data-v-200ba088] {
  background: white;
  height: 100%;
}
.routine .apptype-card[data-v-200ba088] {
    margin-top: 12px;
    background: whitesmoke;
    width: 300px;
    margin-left: 20px;
}
.routine .apptype-card[data-v-200ba088]:hover {
      background: transparent;
}
.routine .apptype-card[data-v-200ba088] .el-card__body {
      padding: 0px;
}
.routine .apptype-card .apptype-card-header[data-v-200ba088] {
      font-size: 16px;
      color: #606266;
      padding: 8px 20px;
      text-align: center;
      border-top: 4px solid #ffc62e;
      border-bottom: 1px solid #ebeef5;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.routine .apptype-card .apptype-card-body[data-v-200ba088] {
      padding: 10px;
      color: #606266;
      /*max-height: 200px;*/
      /*overflow-y: scroll;*/
}
.routine .apptype-card .apptype-card-body .app-item[data-v-200ba088] {
        padding: 0px 10px;
        cursor: pointer;
}
.routine .apptype-card .apptype-card-body .app-item[data-v-200ba088]:hover {
          background: rgba(204, 204, 204, 0.1);
          color: #409eff;
}
.routine .apptype-card .apptype-card-body .app-item .app-item-dept[data-v-200ba088] {
          float: right;
          color: #409eff;
}
.routine .apptype-card .apptype-card-body .app-item .app-item-dept[data-v-200ba088]:hover {
            -webkit-transform: scale(1.5);
                    transform: scale(1.5);
}
